<template>
    <div id="home">
            <div class="lg:flex justify-between items-center mb-6">
              <p class="text-2xl font-semibold mb-2 lg:mb-0">Өдрийн мэнд <a class="text-green-700">{{ user?.username?.charAt(0).toUpperCase() + user?.username?.slice(1) }}</a></p>
            </div>

            <div class="flex flex-wrap -mx-3 mb-20">

              <div class="w-1/2 xl:w-1/4 px-3">
                <div class="w-full bg-white border text-uzey-100 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
                  <i class="fa-regular fa-hourglass-half fa-3x fill-current mr-4 hidden lg:block"></i>
                  <div class="text-gray-700">
                    <p class="font-semibold text-3xl">
                      <countTo :startVal='startValue' :endVal='myTotalTicketCount' :duration='3000'></countTo>
                    </p>
                    <p>Яг одоо</p>
                  </div>

                </div>
              </div>

              <div class="w-1/2 xl:w-1/4 px-3">
                <div class="w-full bg-white border text-uzey-100 rounded-lg flex items-center p-6 mb-6 xl:mb-0">
                  <i class="fa-solid fa-calendar-week fa-3x fill-current mr-4 hidden lg:block"></i>

                  <div class="text-gray-700">
                    <p class="font-semibold text-3xl">
                      <countTo :startVal='startValue' :endVal='lastWeekEventCount' :duration='3000'></countTo>
                    </p>
                    <p>Энэ 7 хоногт</p>
                  </div>
                </div>
              </div>

              <div class="w-1/2 xl:w-1/4 px-3">
                <div class="w-full bg-white border text-uzey-100 rounded-lg flex items-center p-6">
                  <i class="fa-solid fa-spinner fa-3x fill-current mr-4 hidden lg:block"></i>
                  <div class="text-gray-700">
                    <p class="font-semibold text-3xl">
                      <countTo :startVal='startValue' :endVal='endComingSoonValue' :duration='3000'></countTo>
                    </p>
                    <p>Тун удахгүй</p>
                  </div>
                </div>
              </div>

              <div class="w-1/2 xl:w-1/4 px-3">
                <div class="w-full bg-white border text-uzey-100 rounded-lg flex items-center p-6">
                  <i class="fa-solid fa-spinner fa-3x fill-current mr-4 hidden lg:block"></i>

                  <div class="text-gray-700">
                    <p class="font-semibold text-3xl">
                      <countTo :startVal='startValue' :endVal='endComingSoonValue' :duration='3000'></countTo>
                    </p>
                    <p>Тун удахгүй</p>
                  </div>

                </div>
              </div>

            </div>

            <div class="flex flex-wrap -mx-3">

              <div class="w-full xl:w-1/3 px-3">
                <p class="text-xl font-semibold mb-4">Recent Events</p>

                <div class="w-full bg-white border rounded-lg p-4 mb-8 xl:mb-0">
                  <canvas id="buyers-chart" width="600" height="400"></canvas>
                </div>
              </div>

              <div class="w-full xl:w-1/3 px-3">
                <p class="text-xl font-semibold mb-4">Recent Consumers</p>

                <div class="w-full bg-white border rounded-lg p-4 mb-8 xl:mb-0">
                  <canvas id="reviews-chart" width="600" height="400"></canvas>
                </div>
              </div>

              <div class="w-full xl:w-1/3 px-3">
                <p class="text-xl font-semibold mb-4">Recent Scanned</p>
                <div class="w-full bg-white border rounded-lg p-4">
                  <div class="w-full bg-gray-100 border rounded-lg flex justify-between items-center px-4 py-2 mb-4" v-for="ticket in recentTickets" :key="ticket.ticket_refer">
                      <div>
                          <p class="font-semibold text-xl">{{ ticket.frequency_time }}</p>
                          <p>{{ ticket.frequency_time }}</p>
                      </div>
                      <span class="text-green-500 font-semibold text-lg">{{ ticket.frequency_time }}</span>
                  </div>
                </div>
              </div>

            </div>

    </div>
</template>

<script>
import Chart from 'chart.js'
import axios from 'axios'
import { getUser, getToken } from '../utils/auth'
import countTo from 'vue-count-to';

export default {
    name: 'DashboardHome',
    components: { countTo },
    data() {
        return {
          startValue: 0,
          lastWeekEventCount: 0,
          myTotalTicketCount: 0,
          endValue: 100,
          endComingSoonValue: 999,
          user: null,
          recentTickets: [],
          recentEvents: {
              type: 'line',
              data: {
                  labels : ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"],
                  datasets:[{
                      backgroundColor : "rgba(255, 99, 132, 0.5)",
                      label: 'Энэ 7 хоногт',
                      pointColor : "#fff",
                      data : [0,6,0,3,0,0,0]
                  },
                  {
                      backgroundColor : "rgba(153, 102, 255, 0.5)",
                      pointColor : "#fff",
                      label: 'Өмнөх 7 хоногт',
                      data : [0,0,0,2,0,0,0]
                  }]
              },
              options: {
                  legend: {
                      display: true
                  },
                  scales: {
                      yAxes: [{
                          gridLines: {
                              display: true
                          },
                          ticks: {
                              display: true
                          }
                      }],
                      xAxes: [{
                          gridLines: {
                              display: false
                          }
                      }]
                  }
              }
          },
          reviewsData: {
              type: 'bar',
              data: {
                  labels : ["Mon","Tue","Wed","Thu","Fri","Sat","Sun"],
                  datasets:[{
                    backgroundColor: [
                      'rgba(255, 99, 132, 0.9)',
                      'rgba(255, 159, 64, 0.9)',
                      'rgba(255, 205, 86, 0.9)',
                      'rgba(75, 192, 192, 0.9)',
                      'rgba(54, 162, 235, 0.9)',
                      'rgba(153, 102, 255, 0.9)',
                      'rgba(201, 203, 207, 0.9)'
                    ],
                    strokeColor : "#63b3ed",
                    pointColor : "#fff",
                    pointStrokeColor : "#63b3ed",
                    data : [203,156,99,251,305,247,256]
                  }]
              },
              options: {
                  legend: {
                      display: false
                  },
                  scales: {
                      yAxes: [{
                          gridLines: {
                              display: true
                          },
                          ticks: {
                              display: true
                          }
                      }],
                      xAxes: [{
                          gridLines: {
                              display: false
                          }
                      }]
                  }
              }

          }
        }
    },
    async created() {
      this.user = await getUser()
      const token = await getToken()
      const { data } = await axios.get('https://rest.toktok.mn/ticker-reader-dev/dashboard', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      this.recentEvents.data.datasets = data.data.eventCountByWeekSplited.map((item, index) => {
        return {
          label: item.labal,
          backgroundColor: index === 0 ? 'rgba(255, 99, 132, 0.5)' : 'rgba(153, 102, 255, 0.5)',
          pointColor : "#fff",
          data : item.data.map(item => item.count)
        }
      })
      this.recentEvents.data.labels = data.data.eventCountByWeekSplited[0].data.map(item => item.weekName)
      this.reviewsData.data.datasets[0].data = data.data.ticketDayByCountByLastWeekWithZero.map(item => item.count)
      this.reviewsData.data.labels = data.data.ticketDayByCountByLastWeekWithZero.map(item => item.weekName)
      new Chart(document.getElementById('reviews-chart'), this.reviewsData)
      new Chart(document.getElementById('buyers-chart'), this.recentEvents)
      this.lastWeekEventCount = data.data.lastWeekEventCount
      this.myTotalTicketCount = data.data.myTotalTicketCount
      this.recentTickets = data.data.mylaast10Ticket
    }
}
</script>