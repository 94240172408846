import { Auth } from "aws-amplify";
async function getUser() {
  try {
    const user = await Auth.currentAuthenticatedUser()
    return user
  } catch (err) {
    console.log(err)
    return null
  }
}
async function getToken() {
  return await Auth.currentSession()
    .then(session => {
      if (session) {
        return session.idToken.jwtToken;
      } else {
        return null;
      }
    })
    .catch(err => {
      // eslint-disable-next-line
    console.log(err)
    });
}

async function signIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    if (user) {
      return {
        status: "success",
        user
      }
    }
  } catch (err) {
    return {
      status: "error",
      error: err
    }
  }
}

function signOut() {
  return Auth.signOut()
    .then(data => {
      return data;
    })
    .catch(err => {
      // eslint-disable-next-line
    console.log(err)
    });
}

export {
  getUser,
  signIn,
  signOut,
  getToken
};
