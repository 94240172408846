<template>
  <div class="w-full" >
    <div class="lg:flex justify-between items-center mb-6">
      <p class="text-2xl font-semibold mb-2 lg:mb-0">Events</p>
      <button class="bg-toktokyellow-100 hover:bg-blue-600 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow">Уншуулах</button>
    </div>
    <div>
      <div class="max-w-sm w-full lg:max-w-full lg:flex mb-5" v-for="event in events" :key="event.ticket_event_id" >
        <div class="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden" :style="'background-image: url(\'' + event.image[0] +  '\')'" :title="event.name_mon">
        </div>
        <div class="border-r border-b border-l border-gray-400 lg:border-l-0 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
          <div class="mb-8">
            <p class="text-sm text-gray-600 flex items-center">
              <i class="fa-solid fa-location-dot fill-current text-gray-500 w-3 h-3 mr-2"></i>
              {{ event.address.full_address }}
            </p>
            <div class="text-gray-900 font-bold text-xl mb-2">{{ event.name_mon }}</div>
            <p class="text-gray-700 text-base">{{ event.description }}</p>
          </div>
          <div class="flex items-center">
            <i class="fa-solid fa-calendar fa-2x w-10 h-10 rounded-full mr-4" ></i>
            <div class="text-sm">
              <p class="text-gray-600">{{ event.frequency?.[0]?.date || event.created_at }}</p>
            </div>
          </div>
        </div>
      </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import { getToken } from '../utils/auth'
export default {
    name: 'Events',
    data() {
        return {
          events: []
        }
    },
    methods: {
    },
    async created() {
      const token = await getToken()
      const { data } = await axios.get('https://rest.toktok.mn/ticker-reader-dev/event', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      this.events = data.data.datas
    }
}
</script>