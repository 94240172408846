import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'

import Dashboard from '@/components/Dashboard'
import DashboardHome from '@/pages/Home'
import Events from '@/pages/Events'
import Reader from '@/pages/Reader'
import Login from '@/pages/Login'
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import store from './store'
import { getUser } from '@/utils/auth'
import '@/assets/css/tailwind.css'

import VueQrcodeReader from "vue-qrcode-reader"

Vue.use(VueQrcodeReader)
Vue.config.productionTip = false
Amplify.configure(awsconfig)
Vue.use(Router)

const routes = [
  { path: '/', redirect: { name: 'DashboardHome' } },
  { path: '/main', component: Dashboard, children: [
      { path: '/', redirect: { name: 'DashboardHome' } },
      { path: 'home', name: 'DashboardHome', component: DashboardHome },
      { path: 'events', name: 'Events', component: Events },
      { path: 'reader', name: 'Reader', component: Reader }
    ]
  },
  { path: '/login', name: 'Login', component: Login },
  { path: '*', redirect: { name: 'DashboardHome' } }
]

const router = new Router({
  mode: 'history',
  routes
})
router.beforeResolve(async (to, from, next) => {
  const user = await getUser();
  console.log('user', user)
  if (to.path === '/login' && user) {
    return next('/main/home');
  } else if (to.path !== '/login' && !user) {
    return next('/login');
  } else {
    return next();
  }
});

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

