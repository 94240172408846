<template>
  <div>
    <div v-if="showModal" class="w-screen overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
            <h3 class="text-base font-semibold">
              {{ modalData.title }}
            </h3>
            <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="closeModal()">
              <span class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <img class="object-cover" :src="'https://chart.googleapis.com/chart?chs=200x200&cht=qr&chl=' + modalData.qrData" />
            <p class="my-4 text-slate-500 text-lg leading-relaxed">
              {{ modalData.message }}
            </p>
          </div>
          <!--footer-->
          <div class="">
            <button class="h-10 px-5 m-2 w-64 text-indigo-100 transition-colors duration-150 bg-green-700 hover:bg-green-800 rounded-lg focus:shadow-outline" v-on:click="closeModal()">
              Хаах
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    showModal: {
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      default: () => {}
    },
    modalData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
    }
  },
  methods: {
  }
}
</script>