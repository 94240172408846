<template>
    <div class="w-full border-t-2 px-8 py-6 lg:flex justify-between items-center">
        <div></div>
        <div class="flex">
            <!-- <a href="#" class="mr-6 hover:text-gray-900">Terms of Service</a>
            <a href="#" class="mr-6 hover:text-gray-900">Privacy Policy</a> -->
            <a href="https://uzey.mn" target="_blank" class="hover:text-gray-900">© Copyright 2023 uzey.mn</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>