<template>
  <div>
    <div class="w-full">
      <div class="lg:flex justify-between items-center mb-6">
        <p class="text-2xl font-semibold mb-2 lg:mb-0">Reader</p>
      </div>
      <!-- Place holder with a button -->
      <p class="decode-result">
        Last result: <b>{{ result }}</b>
      </p>

      <div
        class="border-8 overflow-hidden border-gray-900 bg-gray-900 rounded-3xl flex flex-col justify-center items-center bg-no-repeat bg-cover shadow-2xl"
      >
        <div class="text-center z-10">
          <div
            class="border-corner p-5 relative m-auto rounded-xl bg-cover w-full h-full flex"
          >
            <span class="border_bottom"></span>
            <qrcode-stream @decode="onDecode" @init="onInit"></qrcode-stream>
          </div>
          <p class="text-gray-300 text-xs mt-3">
            <button
              class="bg-uzey-100 focus:outline-none rounded-lg px-6 py-2 text-white font-semibold shadow"
            >
              Scan a QR Code
            </button>
          </p>
        </div>
      </div>
    </div>
    <Modal
      :showModal="isShowModal"
      :closeModal="closeModal"
      :modalData="modalData"
    ></Modal>
  </div>
</template>

<script>
import axios from "axios";
import { getToken } from "../utils/auth";
import Modal from "../components/Modal.vue";
export default {
  name: "Reader",
  components: {
    Modal,
  },
  data() {
    return {
      isShowModal: false,
      modalData: {
        title: "Алдаа",
        qrData: "ticketRefer",
        message: "Алдаа гарлаа",
      },
      token: "",
      result: "",
      error: "",
    };
  },
  async created() {
    this.token = await getToken();
  },
  methods: {
    closeModal() {
      this.isShowModal = false;
    },
    async onDecode(result) {
      const response = await axios.post(
        "https://rest.uzey.mn/reader/scanTicketQR",
        {
          ticket_qr_data: result,
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        }
      );
      console.log(response.data);
      if (response.data.status === "success") {
        this.modalData.title = "Амжилттай";
        this.modalData.message = "Амжилттай бүртгэгдлээ";
      } else {
        this.modalData.title = "Алдаа";
        this.modalData.message =
          response.data.message || response.data.error.message;
      }
      this.isShowModal = true;
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.error =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      }
    },
  },
};
</script>
<style>
.border-corner:before {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  border-top: 5px solid #0ed3cf;
  border-left: 5px solid #0ed3cf;
  border-radius: 12px 0;
}

.border-corner:after {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  border-top: 5px solid #0ed3cf;
  border-right: 5px solid #0ed3cf;
  border-radius: 0 12px;
}

.border-corner span.border_bottom:before {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 5px solid #0ed3cf;
  border-left: 5px solid #0ed3cf;
  border-radius: 0 12px;
}

.border-corner span.border_bottom:after {
  display: block;
  content: "";
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-bottom: 5px solid #0ed3cf;
  border-right: 5px solid #0ed3cf;
  border-radius: 12px 0;
}

.camera {
  z-index: 11;
}

.camera::before {
  content: "";
  position: absolute;
  top: 15%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  transform: translate(-50%, -50%);
  border: solid 2px #2c303a;
}

.shadow-out {
  box-shadow: rgba(17, 24, 39, 0.2) 0px 7px 29px 0px;
}
</style>
