const awsmobile = {
  aws_project_region: "ap-southeast-1",
  aws_cognito_identity_pool_id:
    "ap-southeast-1:93b76094-ae5e-4027-9464-2e7a6ea5cd27",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_0KXVwB56m",
  aws_user_pools_web_client_id: "g0gve07op1s6vrqr0m58iukqa",
  oauth: {}
};
export default awsmobile;
